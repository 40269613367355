import './App.scss';
import React, {useRef} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from 'react-bootstrap';
import NavigationBar from './components/navbar/navbar';
import HeadLine from './components/headline/headline';
import LeftSideBar from './components/left-sidebar/left-sidebar';
import RightSideBar from './components/right-sidebar/right-sidebar';
import About from './components/about/about';
import Skills from './components/skills/skills';
import Work from './components/work/work';
import Projects from './components/projects/projects';
import Contact from './components/contact/contact';

function App() {
  const aboutRef = useRef(null);
  const workRef = useRef(null);
  const projectsRef = useRef(null);
  const skillsRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToAbout = () => aboutRef.current.scrollIntoView();
  const scrollToSkills = () => skillsRef.current.scrollIntoView();
  const scrollToWork = () => workRef.current.scrollIntoView(); 
  const scrollToProjects = () => projectsRef.current.scrollIntoView();
  const scrollToContact = () => contactRef.current.scrollIntoView(); 

  return (
    <div className="App">
      <NavigationBar
        scrollToWork={scrollToWork}
        scrollToAbout={scrollToAbout}
        scrollToSkills={scrollToSkills}
        scrollToProjects={scrollToProjects}
        scrollToContact={scrollToContact}
      />
      <LeftSideBar/>
      <RightSideBar/>
      <HeadLine/>
      <Container className="text-center custom-container">
        <Row className="row" ref={aboutRef}>
          <About/>
        </Row>
        <Row className="row" ref={skillsRef}>
          <Skills/>
        </Row>
        <Row className="row" ref={workRef}>
          <Work/>
        </Row>
        <Row className="row" ref={projectsRef}>
          <Projects/>
        </Row>
        <Row className="row" ref={contactRef}>
          <Contact/>
        </Row>
      </Container>
    </div>
  );
}

export default App;
