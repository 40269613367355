import React from "react";
import Nav from 'react-bootstrap/Nav';

import "./right-sidebar-styles.scss";

function RightSideBar() {
  return (
    <Nav fixed="bottom" className="rightSideNav flex-column">
      <Nav.Link className="emailAddress" href="mailto:jwboon@pm.me">jwboon@pm.me</Nav.Link>
      <div className="vl-right" />
    </Nav>
  );
}

export default RightSideBar;
