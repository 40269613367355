import React from "react";
import Nav from 'react-bootstrap/Nav';

import "./left-sidebar-styles.scss";

function LeftSideBar () {
  return (
    <Nav fixed="bottom" className="leftSideNav flex-column">
      <Nav.Link
        className="navlinkleft"
        href="https://www.linkedin.com/in/jamesboon-dev"
        target="_blank"
      >
        <img src="assets/002-linkedin-1.png" alt="linkedin"></img>
      </Nav.Link>
      <Nav.Link
        className="navlinkleft"
        href="https://github.com/JimmyBoon"
        target="_blank"
      >
        <img src="assets/003-github.png" alt="github"></img>
      </Nav.Link>
      <Nav.Link
        className="navlinkleft"
        href="mailto:jwboon@pm.me"
        target="_blank"
      >
        <img src="assets/006-mail.png" alt="email"></img>
      </Nav.Link>
      <div className="vl-left" />
    </Nav>
  );
}

export default LeftSideBar;
