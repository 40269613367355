import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "../section-title/section-title";
import "./work-styles.scss";

function Work(props) {
  return (
    <Container className="WorkMain">
        <Row>
            <SectionTitle number={"03."} title={"Work"} side={"left"} />
        </Row>
        <Row className="WorkContent">
            <Col xs={12} md={6} >
                <hr />
                    <p>
                        <strong> Aviation: </strong> Over a decade of experience as an Airline Operations Controller and Flight Dispatcher with various airlines, including Virgin Australia.<br/><br/>
                        <strong> Full Stack Web Developer: </strong> Front and backend web development. <br/><br/>
                        <strong> Python Instructor: </strong> Tutoring Python and programming skills at Queensland University of Technology. <br/><br/>
                        <strong> Software/Simulations Engineer: </strong> Building simulated worlds, digital twins and rapid prototypes at Boeing Defense Australia and Phantom Works Global.

                    </p>
                <hr />
                <Row className="linkedin-row">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/jamesboon-dev"
                        className="icon"
                        >
                        <img src="assets/002-linkedin-1.png" alt="github" />
                    </a>
                </Row>
   
            </Col>
            <Col xs={12} md={6}>
                <img className="image" src="airliner2.png" alt="Paper plane" />
            </Col>
        </Row>
    </Container>
  );
}

export default Work;
