import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { CiMenuBurger } from "react-icons/ci";
import { LiaTimesSolid } from "react-icons/lia";


import './navbar-styles.scss';

function NavigationBar(props) {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Navbar expand="lg" className="custom-navbar">
        <Container>
            <Navbar.Brand href="#home">
            </Navbar.Brand>
            <Navbar.Toggle className='ms-auto' aria-controls="basic-navbar-nav" onClick={handleToggle}>
                        {isOpen ? <LiaTimesSolid /> : <CiMenuBurger />}
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav" className="NavRight">
            <Nav className="NavRight" >
                <Nav.Link onClick={props.scrollToAbout} className="NavText">
                    <p className="LinkNumber">01.</p>
                    <p className="LinkName">About</p>
                </Nav.Link>
                <Nav.Link onClick={props.scrollToSkills} className="NavText">
                    <p className="LinkNumber">02.</p>
                    <p className="LinkName">Skills</p>
                </Nav.Link>
                <Nav.Link onClick={props.scrollToWork} className="NavText">
                    <p className="LinkNumber">03.</p>
                    <p className="LinkName">Work</p>
                </Nav.Link>
                <Nav.Link onClick={props.scrollToProjects} className="NavText">
                    <p className="LinkNumber">04.</p>
                    <p className="LinkName">Projects</p>
                </Nav.Link>
                <Nav.Link onClick={props.scrollToContact} className="NavText">
                    <p className="LinkNumber">05.</p>
                    <p className="LinkName">Contact</p>
                </Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    );
}

export default NavigationBar;