import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "../section-title/section-title";
import "./skills-styles.scss";

function Skills(props) {
  return (
    <Container className="SkillsMain">
        <Row>
            <SectionTitle number={"02."} title={"Skills"} side={"right"} />
        </Row>
        <Row className="SkillsContent">
            <Col xs={12} md={6}>
                <img src="whale.png" alt="This is me" />
            </Col>
            <Col xs={12} md={6} >
            <hr />
                <p>
                    I am highly adaptable and always keen to take on a new challenge.
                    My primary IT skills are listed here, but with a bit of time, I can master almost anything.
                    <br/><br/>
                    <strong>Programming Languages:</strong>  C, C++, C#, Python, JavaScript.
                    <br/><br/>
                    <strong>Frameworks:</strong>  React, Unity 3D.
                    <br/><br/>
                    <strong>Tools:</strong>  Git, Docker.
                    <br/><br/>
                    <strong>Project Management:</strong>  Agile, Scrum Master.
                </p>
                <hr />
            </Col>

        </Row>
    </Container>
  );
}

export default Skills;