import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./project-item-styles.scss";

function ProjectItem(props) {
  return props.side === "right" ? (
    <Container className="projectRight">
        <Row>
            <hr />
            <h3>{props.title}</h3>
            <Col xs={12} md={6}>
                {props.description}

                <div className="links">
                    {props.links}
                </div>
                
            </Col>
            <Col xs={12} md={6}>
                {props.mediaType === "image" ?
                    (
                        <img src={props.mediaSrc}/>
                    )
                    :
                    (
                        <iframe          
                        className="video"
                        title="0"
                        autoPlay
                        src={props.mediaSrc} 
                        allowFullScreen
                        />
                    )
                };
                
            </Col>
        </Row>
    </Container>
  ) : (
    <Container className="projectLeft">
        <Row>
            <hr />
            <h3>{props.title}</h3>
            <Col xs={12} md={6}>
                {props.mediaType === "image" ?
                    (
                        <img className="itemImage" src={props.mediaSrc}/>
                    )
                    :
                    (
                        <iframe          
                        className="video"
                        title="0"
                        autoPlay
                        src={props.mediaSrc} 
                        allowFullScreen
                        />
                    )
                };
            </Col>
            <Col xs={12} md={6}>


                {props.description}

                <div className="links">
                    {props.links}
                </div>
            </Col>
        </Row>
    </Container>
  );
}

export default ProjectItem;