import React from "react";
import "./section-title-styles.scss";

function SectionTitle(props) {
  return props.side === "right" ? (
    <div className="sectionTitleRight">
      <h3 className="numberTitle">{props.number}</h3>
      <h3 className="wordTitle">{props.title}</h3>
    </div>
  ) : (
    <div className="sectionTitleLeft">
      <h3 className="numberTitle">{props.number}</h3>
      <h3 className="wordTitle">{props.title}</h3>
    </div>
  );
}

export default SectionTitle;