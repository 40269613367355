import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "../section-title/section-title";
import "./about-styles.scss";

function About(props) {
  return (
    <Container className="AboutMain">
        <Row>
            <SectionTitle number={"01."} title={"About"} side={"left"} />
        </Row>
        <Row className="AboutContent">
            <Col xs={12} md={6} >
            <hr/>
            
                <p>
                    I began studying web and game development in 2015, learning from online tutorials and working on
                    personal projects. In 2021, I decided to I decided to leave my airline operations career and commit to IT.<br/><br/> In 2023, I completed
                    a computer science degree at <strong>Queensland Univeristy of Technology</strong>. <br/><br/> Currently I am working as a Software and Simulations
                    engineer with <strong>Boeing Defense Australia</strong>.
                    <br/><br/> Aside from IT, my interests include sailing, surfing and travel.
                </p>
                <hr/>
            </Col>
            <Col xs={12} md={6}>
                <img src="boat.png" alt="Origami boat" />
            </Col>
        </Row>
    </Container>
  );
}

export default About;
