import React from "react";
import { Container } from "react-bootstrap";
import SectionTitle from "../section-title/section-title.jsx";
import "./contact-styles.scss";

function Contact(props) {
  return (
    <Container className="ContactMain">
      <SectionTitle number={"05."} title={"Contact"} side={"lefy"} />
      <div className="ContactInner">
        <p>
        <strong>Get In Touch</strong>, I would love to hear from you:<br/><br/>
          <a href="mailto:jwboon@pm.me">jwboon@pm.me</a>
        </p>
      </div>
    </Container>
  );
}

export default Contact;
