import React, {useState, useEffect} from "react";
import Container from 'react-bootstrap/Container';

import "./headline-styles.scss";

function HeadLine() {

    const [text, setText] = useState("");
    const [fullText] = useState(
      `Software and simulations engineer. With code, algorithms, and mathematics, I build simulated worlds, digital twins and strive to develop elegant solutions for complex problems.`
    );
    const [index, setIndex] = useState(0);

    useEffect(() => {
      if (index < fullText.length) {
        setTimeout(() => {
          setText(text + fullText[index]);
          setIndex(index + 1);
        }, 50);
      }
    }, [index, fullText, text]);

  return (
    <Container className="headlineContainer">
      <p>Hi, my name is:</p>
      <h1>James Boon.</h1>
      <h1 className="things">I code amazing things.</h1>
      <p className="des-text">{text}</p>
    </Container>
  );
}

export default HeadLine;