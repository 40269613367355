import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "../section-title/section-title";
import "./projects-styles.scss";
import ProjectItem from "./project-item";

function Projects(props) {
  return (
    <Container className="ProjectMain">
        <Row>
            <SectionTitle number={"04."} title={"Featured Projects"} side={"right"} />
        </Row>

        <ProjectItem 
          side="left" 
          title="The Extras" 
          mediaType="video" 

          mediaSrc="https://player.vimeo.com/video/673382651?h=e4d4b79393?&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&sidedock=0" 
          description= {
          <p>'The Extras' is an AI demonstration, where the characters explore, eat,
          drink, interact and live in their own world. As an AI demonstration,
          ‘The Extras’ is not actually a game, it could be considered as a
          component of a future RPG type game, where the characters are a form of
          active scenery. Build with: <code>Unity 3D, C#</code>.
          <br />
          <br /> <strong>Key Features:</strong>
          <br /> - Intelligent Camera System <br /> - Character AI <br /> -
          Character Conversation System <br /> - Day/Night Effects <br /> -
          Weather System <br /> </p>}

          links={      
          <>    
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/JimmyBoon/AI-Demonstrator-The-Extras"
              className="icon"
            >
              <img src="assets/004-github-1.png" alt="github" />
            </a>
          </>}
        />

        <ProjectItem 
          side="right" 
          title="Get the flock out of here!" 
          mediaType="video" 
          mediaSrc="https://player.vimeo.com/video/673391333?h=544dc8256e"
          description=
          {
            <p>
              <strong>Description:</strong> 'Get the Flock Out of Here' is a sheep
              herding game. It is a minimum viable product with one level. Developed
              in just three days to demonstrating, rapid design and deployment skills.
              Build with: <code>Unity 3D, C#</code>.
              <br />
              <br />
              <strong>Key Features:</strong>
              <br /> - Fully playable <br /> - Complex sheep flocking algorithm <br />{" "}
              - 3D Environment <br />
              <br />
              
          </p>
          }
          links={
            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/JimmyBoon/Get-The-Flock-Out-Of-Here"
                className="icon"
              >
                <img src="assets/004-github-1.png" alt="github" />
              </a>
            </>
          }
        />

        <ProjectItem 
          side="left" 
          title="Heading Pi" 
          mediaType="image" 
          mediaSrc="compass.png"
          description=
          {
            <p>
              <strong>Description:</strong> Heading Pi is built with a Raspberry Pi and Sense Hat. It takes the heading information from the Sense Hat and converts it into NMEA 0183 format, which is then sent via UDP into my boat naviagtion network.
              Build with: <code>Python, Linux</code>.
              <br />
              <br />
              <strong>Key Features:</strong>
              <br /> - Provides heading information my boat's naviagtion system. 
              <br /> - Integrates into NMEA 0183 / NMEA 2000 system.
              <br /> - Saves over $1000 on a marine heading sensor. <br />
              <br />
              
            </p>
          }
          links={
            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/JimmyBoon/"
                className="icon"
              >
                <img src="assets/004-github-1.png" alt="github" />
              </a>
            </>
          }
        />  
        

    </Container>
  );
}

export default Projects;